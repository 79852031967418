import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const StyledMarkdown = styled.div`
 font-family: 'MetaSerifPro-Medi';
 font-size:18px;
 text-align: justify;
 text-justify: inter-word;
 strong{
    color:black;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
 }
`;

export const StyledP = styled.p`
   color:black;
   font-size: 18px;
   font-weight: 300;
`;

export const StyledColData = styled(Col)`
   margin: 0 auto;
   max-width: 1200px;
   text-align: justify;
`;


export const StyledCol = styled(Col)`
   padding: 0 0 10px 10px;
   font-weight: 300;
   max-width: 1200px;
   margin: 0 auto;
   text-align: initial;
`;

export const StyledH1 = styled.h1`
   color:black;
   {media (max-width: 768px) {
      font-size: 28px;
      font-weight: 700;
   }
   {media (max-width: 576px) {
      font-size: 24px;
      font-weight: 700;
   }
`;

export const StyledDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 1200px;
   padding: 0 10px;
   padding-top: 60px;
   justify-content: center;
   height: 200px;
   margin: 30px auto;
   background-color: #f5f5f5;
`;


export const StyledButton = styled(Button)`
   margin: 0 auto;
   background-color: #000;
   color: white;
   border: 0;
   padding: 10px 20px;
   font-size: 16px;
   font-weight: 400;
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   &:hover {
      background-color: #222;
   }
   `;

export const StyledDivYoutube = styled.div`
   position: relative;
   padding-bottom: 56.25%; /* 16:9 */
   padding-top: 25px;
   height: 0;
`;

export const StyledIframe = styled.iframe`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
`;

 export const StyledRow = styled(Row)`
   padding: 30px 0 30px 0;
   font-weight: 300;
   max-width: 500px;
   max-height: 200px;
   margin: 0 auto;
`;

export const StyledRowButton = styled(Row)`
   padding: 0 0 20px 0;
   font-weight: 300;
   max-width: 1200px;
   margin: 0 auto;
`;

export const StyledImg = styled.img`
   width: 300px;
   height: 200px;
   float: left;
   margin: 0 10px 10px 10px;
`;