import { createGlobalStyle } from 'styled-components';

import MetaSerifProBookWoof from '../../fonts/MetaSerifPro-Book.woff'
import MetaSerifProBookWoof2 from '../../fonts/MetaSerifPro-Book.woff2'
import MetaSerifProMediWoof from '../../fonts/MetaSerifPro-Medi.woff'
import MetaSerifProMediWoof2 from '../../fonts/MetaSerifPro-Medi.woff2'
import NexaBoldWoff from '../../fonts/nexa_bold-webfont.woff'
import NexaBoldWoff2 from '../../fonts/nexa_bold-webfont.woff2'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'nexa-bolduploaded_file';
        src: url(${NexaBoldWoff}) format('woff2'),
             url(${NexaBoldWoff2}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
    font-family: 'MetaSerifPro-Medi';
    src: url(${MetaSerifProMediWoof2}) format('woff2'),
        url(${MetaSerifProMediWoof}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }
    @font-face {
        font-family: 'MetaSerifPro-Book';
        src: url(${MetaSerifProBookWoof2}) format('woff2'),
            url(${MetaSerifProBookWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    body{
        font-family: 'nexa-bolduploaded_file', sans-serif;
        color: #8A8F93;
    }
    h1, h2, h3, h4, h5, h6 {
        color: black;
    }
`;

export default GlobalStyle;