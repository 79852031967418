import styled from "styled-components";
import {NavDropdown} from 'react-bootstrap'

export const StyledNavDropdown = styled(NavDropdown)`
    @font-face {
        font-family: 'nexa-bolduploaded_file';
        src: url('/src//fonts/font-awesome/fonts/nexa_bold-webfont.woff2') format('woff2'),
             url('/src/fonts/font-awesome/fonts/nexa_bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    *{
        font-family: 'nexa-bolduploaded_file', sans-serif;
        color: #212121;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.30px;
        text-transform: uppercase;
    }
    .dropdown-menu{
    border-radius: 0px;
}

.dropdown-menu .show{
    border-radius: 0px;
    margin-left:100px
}
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}
a.dropdown-item.disabled{
    color: #0BAECD;
    font-weight: 700;
}
`;
