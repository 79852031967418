import React from "react";
import { useTranslation } from 'react-i18next';
import AONav from "../components/Navbar";
import Footer from "../components/Footer";
import MarkdownRender from "../components/MarkdownRender";
import { getTermsOfUseData } from '../data/Terms';

const TerminoDeUso = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase(); // 'ES' o 'EN'

    const terminoContent = getTermsOfUseData('terminos_y_condiciones', currentLanguage);

    // Asegúrate de que terminoContent no es null antes de intentar acceder a sus propiedades
    if (!terminoContent) {
        return <div>Cargando...</div>;
    }

    return (
        <>
            <AONav/>
            <MarkdownRender 
                content={terminoContent.content}
                title={terminoContent.title}
            />
            <Footer />
        </>
    );
}

export default TerminoDeUso;
