import styled from "styled-components";
import {Row, Carousel, Container} from 'react-bootstrap'

export const StyledNewsContainer = styled(Container)`
 margin:0px;
 margin-top:80px;
 padding-left:50px;
 padding-right:50px;
 @media (max-width: 768px) {
    padding-left:0px;
    padding-right:0px;
    }
`;


export const StyledNewsBox= styled.div`
    background-color:white;
    padding-left:25px;
    padding-right:25px;
    padding-top:10px;
    margin-top:40px;
    @media (max-width: 768px) {
     padding:0;
     margin:0;
     width:100%;
     .col-md-7{
      width:100%;
      height:100%;
     }
    }
`;

export const StyledNewsRow= styled(Row)`
    width:auto;
    top:0;
    left:0;
    position:absolute;
    padding-right:60px;
    @media (max-width: 768px) {
     padding-right:0px;
     }
`;

export const StyledNewsTopDivider= styled.hr`
   border:2px solid black;
   margin-top:10px;
   margin-bottom:0px; 
   opacity:100;
`;

export const StyledNewsBottomDivider= styled.hr`
   border:1px solid black;
   margin-top:10px;
   margin-bottom:0px; 
   opacity:100;
`;

 export const StyledNewsHeadline = styled.h2`
    font-size:32px;
    font-family:'MetaSerifPro-Medi',serif;
    font-weight:700;
`;

 export const StyledNewsCarousel = styled(Carousel)`
   .carousel-control-prev-icon,
   .carousel-control-next-icon {
     margin:0;
     outline: black;
     background-size: 100%, 100%;
     border-radius: 50%;
     border: 1px solid black;
   }
   .carousel-caption {
     left:2rem;
     right:2rem;
     justify-content:center;
   }
   .carousel-control-prev {
     justify-content: flex-start;
   }
   .carousel-control-next {
     justify-content: flex-end;
   }
 
`;

export const StyledNewsText = styled.p`
    color:black;
    `;

export const StyledLink = styled.a`
    color:black;
    text-decoration:none;
    &:hover {
        color:black;
        text-decoration:none;
    `;