import styled from "styled-components";
import {Col, Row} from "react-bootstrap";

export const StyledColAboutUs = styled(Col)`
    md:6;
    padding:50px;
    display:flex;
`;


export const StyledP = styled.p`
    text-decoration:none;
    font-weight:600;
    a {
        text-decoration:none;
        color:#71d8db;
        font-weight:600;
        font-family: 'Monolog', sans-serif;
        &:hover {
            color:#ffffff;
        }
    }
`;