import React from "react";
import AONav from "../components/Navbar";
import Footer from "../components/Footer";
import NewsList from "../components/NewsList";
import {newsData} from "../data/News";
import { Link } from "react-router-dom";

const Noticias = () => (
    <>
        <AONav/>
        <NewsList data={newsData}/>
        <Footer />
    </>
);

export default Noticias;
