import React from 'react';
import {Col, Row} from "react-bootstrap";
import {StyledSection, StyledSectionDark} from "../styles/Section.styled";
import { StyledColAboutUs, StyledP } from '../styles/AboutUs';
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import { useTranslation } from 'react-i18next'; 

const AboutUs = () => {
    const { t } = useTranslation();
    return (
    <StyledSectionDark>
        <Row lg={12}>
            <StyledColAboutUs><Col><img width="100%" src={require('../../images/index/consulting/002.jpg')} alt="Nosotros" /></Col></StyledColAboutUs>
            <StyledColAboutUs><Col>
                <h2>{t('nosotros.titulo')}</h2>
                {t('nosotros.descripcion')}
                <StyledP><p><a href={"/Nosotros"}>{t('nosotros.leerMas')}<ArrowRight/></a></p></StyledP>
            </Col></StyledColAboutUs>

        </Row>
    </StyledSectionDark>
    )
};

export default AboutUs;
