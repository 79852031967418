import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export const StyledColFooter = styled(Col)`
    padding: 50px;
`;

export const StyledLink = styled(Link)`
    text-decoration:none;
    color:#777777;
    font-weight:600;
    font-family: 'Monolog', sans-serif;
    &:hover {
        color:#ffffff;
    }
`;

export const StyledP = styled.p`
    text-decoration:none;
    font-weight:600;
    a {
        text-decoration:none;
        color:#777777;
        font-weight:600;
        font-family: 'Monolog', sans-serif; 
        &:hover {
            color:#ffffff;
        }
    }
`;