import React from "react";
import AONav from "../components/Navbar";
import Footer from "../components/Footer";
import {useParams} from "react-router";
import NewsColumn from "../components/NewsColumn";


const Noticia = () => {
    const { url } = useParams();
    return(
        <>
            <AONav/>
            <NewsColumn url={url}/>
            <Footer />
        </>
    )
}

export default Noticia;
