import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import Team from '../components/index/Team';
import Footer from '../components/Footer';
import { getWebsiteData } from '../data/Website';

const Equipo = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // 'es' o 'en'
  const id = "equipo"; 
  const contentData = getWebsiteData(id, currentLanguage.toUpperCase());

  return (
    <>
      <AONav />
      <Team description={contentData.content} />
      <Footer />
    </>
  );
};

export default Equipo;