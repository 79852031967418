function toUrl(text) {
    if (typeof text !== 'string') {
        console.error('toUrl: el argumento text no es un string o es undefined', text);
        return '';
    }
    return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
}
const raw_data = [
    {
        url: toUrl("Proyecto de Ley Fintech: Un Avance en la Regulación de las Empresas Que Prestan Servicios Financieros Tecnológicos"),
        ES:{
            title: "Proyecto de Ley Fintech: Un Avance en la Regulación de las Empresas Que Prestan Servicios Financieros Tecnológicos",
            author: "ANGUITAOSORIO",
            date: "2022-10-12",
            imagesrc:"/images/blog/fintec-compliance-01.jpg",
            firstParagraph: "Con fecha 12 de octubre del presente año el Proyecto de Ley Fintech fue aprobado por el Senado, pasando a la Cámara de Diputados y que, en caso de ser aprobada, será promulgada como ley por el presidente de la República.",
            //url: toUrl("Proyecto de Ley Fintech: Un Avance en la Regulación de las Empresas Que Prestan Servicios Financieros Tecnológicos"),
            content: `

Este proyecto de ley surge ante la necesidad de crear un marco legal que regule las entidades que prestan servicios financieros a través de la tecnología, debido al gran desarrollo que estas industrias han experimentado en los últimos años y que presentaron un crecimiento exponencial gracias a la pandemia global causada por el COVID-19, que implicó un avance acelerado de este tipo de servicios tecnológicos.

Así las cosas, la ley Fintech busca regular principalmente 2 temas: 1. Establecer un marco regulatorio para promover y facilitar la incorporación de la tecnología en la innovación financiera y 2. Promover un sistema de Finanzas Abiertas (u Open Banking).

Respecto del primer punto, esta ley pretende incorporar y regular los siguientes servicios basados en la tecnología Fintech:

- Plataformas de financiamiento colectivo.
- Sistemas alternativos de transacción de valores e instrumentos financieros (incluyendo criptomonedas).
- Asesoría crediticia y de inversión.
- Custodia de instrumentos financieros.
- Enrutadores de órdenes e intermediarios de instrumentos financieros.

De este modo, las entidades financieras que deseen prestar este tipo de servicios deben de estar inscritos en un Registro de Prestadores de Servicios Financieros que será administrado por la Comisión para el Mercado Financiero (CMF), institución que será la encargada de la regulación, fiscalización y supervisión de estas entidades, conforme lo indica el proyecto de ley.

En relación con el segundo punto, se pretende incorporar un sistema de Finanzas Abiertas cuyo objetivo principal es permitir que los diferentes proveedores de servicios financieros puedan intercambiar información financiera de sus clientes de una manera expedita y segura a través de una interfaz de acceso remoto y automatizado. Sin embargo, el proyecto de ley no sólo hace referencia a información de los clientes sino también a la información respecto de los términos y condiciones generales de los productos y servicios financieros que se ofrecen al público por parte de las entidades.

A su vez, dentro del proyecto de ley se destaca la importancia del consentimiento por parte de los clientes para formar parte de este sistema, ya que, estos deben de otorgarlo de manera previa manifestándolo de forma libre, informada, expresa y especifica; debiendo las entidades financieras adoptar las medidas necesarias para cumplir con los estándares mínimos de seguridad de la información, ciberseguridad y políticas de gestión de riesgos y confidencialidad, para evitar que se divulgue o se efectúen modificaciones a la información, sin consentimiento por parte de los clientes.

Por otra parte, es importante destacar que la promulgación de esta ley también tendrá implicancia en otros cuerpos normativos, ya que, incorpora cambios, por ejemplo, a la ley N° 18.045 de Mercado de Valores, ley N°18.840 del Banco Central, ley N°18.046 sobre Sociedades Anónimas, el decreto con fuerza de ley N°3 de 1997 del Ministerio de Hacienda que fija la ley General de Bancos, entre otras.

En conclusión, a través de la Ley Fintech se busca establecer un marco regulatorio propio para las empresas Fintech con el que no cuentan actualmente y que permitirá un desarrollo adecuado de estas instituciones, centrando su foco la creación de un ordenamiento para la prestación de servicios tecnológicos financieros y en un sistema de finanzas abiertas, y respecto del cual la CMF tendrá un rol preponderante en registro y fiscalización.
`,
        },
        EN:{
            title: "Fintech Law Project: An Advance in the Regulation of Companies Providing Technological Financial Services",
            author: "ANGUITAOSORIO",
            date: "2022-10-12",
            imagesrc:"/images/blog/fintec-compliance-01.jpg",
            firstParagraph: "On October 12 of this year, the Fintech Bill was approved by the Senate, moving on to the Chamber of Deputies, and if approved, will be enacted into law by the President of the Republic.",
            content: `
This bill emerges in response to the need for a legal framework to regulate entities that provide financial services through technology, due to the significant development these industries have experienced in recent years. They have shown exponential growth thanks to the global pandemic caused by COVID-19, which led to a rapid advancement of such technological services.
            
Thus, the Fintech law aims to primarily regulate two areas: 1. Establish a regulatory framework to promote and facilitate the incorporation of technology in financial innovation, and 2. Promote an Open Banking system.
            
Regarding the first point, this law intends to incorporate and regulate the following Fintech-based services:
            
- Crowdfunding platforms.
- Alternative systems for transacting securities and financial instruments (including cryptocurrencies).
- Credit and investment advisory services.
- Custody of financial instruments.
- Order routers and intermediaries of financial instruments.

In this way, financial entities wishing to provide these types of services must be registered in a Financial Service Providers Registry, which will be administered by the Commission for the Financial Market (CMF). This institution will be responsible for the regulation, oversight, and supervision of these entities, as indicated by the bill.
            
In connection with the second point, the bill aims to incorporate an Open Banking system whose main objective is to allow different financial service providers to exchange their clients' financial information quickly and securely through a remote and automated access interface. However, the bill refers not only to client information but also to information about the general terms and conditions of the financial products and services offered to the public by these entities.
            
Moreover, the bill highlights the importance of client consent to be part of this system. Clients must grant it beforehand, freely, informed, expressly, and specifically. Financial entities must take the necessary measures to comply with the minimum standards for information security, cybersecurity, and risk management and confidentiality policies to prevent unauthorized disclosure or modification of information.            

Additionally, it is important to note that the enactment of this law will also have implications for other legal bodies, as it introduces changes, for example, to the Securities Market Law No. 18.045, the Central Bank Law No. 18.840, the Corporations Law No. 18.046, and the decree with the force of law No. 3 of 1997 from the Ministry of Finance, which establishes the General Banking Law, among others.
            
In conclusion, the Fintech Law seeks to establish a specific regulatory framework for Fintech companies, which they currently lack. This framework will allow for the proper development of these institutions, focusing on creating an order for the provision of technological financial services and an open banking system, in which the CMF will play a prominent role in registration and oversight.
`
        },
    },
    {
        url: toUrl("Caso Guacamaya Emco un Problema de Cultura"),
        ES:{
            title: "Caso Guacamaya - EMCO: Un Problema de Cultura",
            author: "ANGUITAOSORIO",
            date: "2022-10-12",
            imagesrc:"/images/misc/026.jpg",
            //url: toUrl("Caso Guacamaya Emco un Problema de Cultura"),
            firstParagraph: "Mucho se ha dicho respecto al ataque informático que sufrió el estado mayor conjunto (EMCO) y que ha involucrado al ministerio de defensa, el consejo de defensa del estado, las FF.AA y otras entidades.",
            content: `
    
cybersecurity complianceA medida avanzan los días, nos enteramos que el ataque del Grupo Guacamaya fue exitoso producto de faltas de probidad básica en IT, tales como parchar servidores y aplicaciones de acuerdo a las recomendaciones del fabricante. La conclusión de los expertos es unánime: los atacantes no requirieron una alta sofisticación técnica para aprovecharse de esta vulnerabilidad. Esto implica que el ataque logró su cometido producto de una falta de cultura de seguridad de la información, una falencia mucho más profunda que la simple inhabilidad de un área técnica.
    
Los conceptos de una cultura de seguridad en IT no es algo nuevo, está sistematizada como concepto en certificaciones internacionales como la ISO 27001 y el SOC 2, y tiene algunos elementos básicos:
    
- Todos los que pertenecen a la organización están llamados a proteger la información, acorde a las políticas de la misma, adicionalmente, los que están en el trabajo del día a día son la primera línea de defensa ya que tienen la mejor visibilidad respecto a potenciales debilidades y ataques.
- La responsabilidad va más allá del cargo o posición que cada uno tenga, las mejores prácticas en esta materia promueven que todos integrantes de una organización puedan denunciar faltas de seguridad, sin miedo a represalias.
- Liderazgo al interior de la organización. Los que están a cargo de ciberseguridad están llamados a ser verdaderos líderes, siendo campeones de la cultura, promoviendola y comunicandola en todo momento.
    
Es este desafío de liderazgo es al que ahora enfrentan nuestras autoridades. La mirada de largo plazo debe ser el generar una política de estado única que coordine la ciberseguridad del Estado.
`
            },
        EN:{
            title: "Guacamaya Case - EMCO: A Cultural Problem",
            author: "ANGUITAOSORIO",
            date: "2022-10-12",
            imagesrc:"/images/misc/026.jpg",
            //url: toUrl("Caso Guacamaya Emco un Problema de Cultura"),
            firstParagraph: "Much has been said about the cyber attack suffered by the Joint Chiefs of Staff (EMCO) which has involved the Ministry of Defense, the State Defense Council, the Armed Forces, and other entities.",
            content: `
    
As days go by, we learn that the Guacamaya Group's attack was successful due to basic integrity lapses in IT, such as patching servers and applications according to the manufacturer's recommendations. The experts' conclusion is unanimous: the attackers did not require high technical sophistication to exploit this vulnerability. This implies that the attack achieved its purpose due to a lack of information security culture, a much deeper flaw than the simple inability of a technical area.

The concepts of a security culture in IT are not something new; it is systematized as a concept in international certifications such as ISO 27001 and SOC 2, and it has some basic elements:
            
Everyone belonging to the organization is called upon to protect information, in accordance with the organization's policies. Additionally, those who are involved in day-to-day work are the first line of defense as they have the best visibility regarding potential weaknesses and attacks.
Responsibility goes beyond the role or position one holds; best practices in this area promote that all members of an organization can report security breaches, without fear of retaliation.
Leadership within the organization. Those in charge of cybersecurity are called to be true leaders, champions of the culture, promoting and communicating it at all times.
It is this leadership challenge that our authorities now face. The long-term view should be to generate a unique state policy that coordinates the cybersecurity of the State.
            
            
            
`
        }  
    },
    {
        url: toUrl("NIC Chile incluyendo nombre, correo y otros datos de personas jurídicas serán entregados a terceros solicitantes"),
        ES:{
            title: "NIC Chile incluyendo nombre, correo y otros datos de personas jurídicas serán entregados a terceros solicitantes",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/nic-chile-datos-01.jpg",
            //url: toUrl("NIC Chile incluyendo nombre, correo y otros datos de personas jurídicas serán entregados a terceros solicitantes"),
            firstParagraph: "En agosto de este año la corte de Santiago rechazó, con un voto disidente, reclamo de ilegalidad presentado por Universidad de Chile, en contra del Consejo para la Transparencia, en relación con entrega de información correspondiente a personas jurídicas y naturales registradas en NIC Chile.",
            content: `
    
Universidad de Chile, en contra del Consejo para la Transparencia, en relación con entrega de información
correspondiente a personas jurídicas y naturales registradas en NIC Chile.
    
Con esta decisión la Universidad de Chile deberá “disponer a la entrega del registro con el nombre de
los dominios, fecha de creación, de expiración y demás datos referidos a personas jurídicas, en su
calidad de titulares del dominio - indicación de su nombre y correo electrónico de contacto”.
    
En relación al uso de estos datos por parte de terceros el Consejo para la Transparencia determinó en su momento
que no se acreditó un potencial perjuicio y consideró especialmente que los registros de los últimos 30 días
ya se encuentran disponibilizarlos en la misma página de NIC Chile.
    
Esto significa que el solicitante tendrá acceso al equivalente a una base de datos de todas las empresas
que han registrado su dominio con NIC Chile.
    
Como bien dice el voto disidente de la Corte de Apelaciones esta decisión es preocupante debido a que:
El hecho que NIC Chile permita<i>“buscar de manera singular y específica un nombre de dominio, no
guarda relación ni proporción alguna con la entrega masiva de información que ha ordenado la
decisión cuestionada.”.
- Permite ejecución de campañas masivas.
- Permite especulación con las marcas al registrar su vencimiento.
    
Cabe destacar que la entrega de esta información no implica una autorización para que la misma sea utilizada
en forma perjudicial para las personas registradas en ellas. `
        },
        EN:{
            title: "NIC Chile, including names, emails, and other data of legal entities, will be handed over to requesting third parties",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/nic-chile-datos-01.jpg",
            //url: toUrl("NIC Chile incluyendo nombre, correo y otros datos de personas jurídicas serán entregados a terceros solicitantes"),
            firstParagraph: "In August of this year, the Santiago court rejected, with a dissenting vote, a claim of illegality presented by the University of Chile, against the Council for Transparency, regarding the delivery of information corresponding to legal and natural persons registered in NIC Chile.",
            content: `
    
The University of Chile, against the Council for Transparency, in relation to the delivery of information corresponding to legal and natural persons registered in NIC Chile.

With this decision, the University of Chile must "arrange for the delivery of the register with the names of the domains, date of creation, expiration, and other data referring to legal entities, in their capacity as domain holders - indicating their name and contact email address."
            
Regarding the use of this data by third parties, the Council for Transparency determined at the time that a potential harm was not accredited and especially considered that the records of the last 30 days are already available on the NIC Chile website.
            
This means that the requester will have access to the equivalent of a database of all companies that have registered their domain with NIC Chile.
            
As the dissenting vote of the Court of Appeals rightly points out, this decision is concerning because:
The fact that NIC Chile allows "to search in a singular and specific way for a domain name, bears no relation or proportion whatsoever to the massive delivery of information ordered by the questioned decision."
            
- It allows the execution of massive campaigns.
- It allows speculation with brands by registering their expiration.

It should be noted that the delivery of this information does not imply an authorization for it to be used in a way that is harmful to the people registered in it.`
        }
    },
    {
        url: toUrl("Fintech y Compliance: Regulador de EE.UU fiscaliza fusión de Banco Fintech y declara preocupación por falta de normativa"),
        ES:{
            title: "Fintech y Compliance: Regulador de EE.UU fiscaliza fusión de Banco Fintech y declara preocupación por falta de normativa",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/fintec-compliance-01-small.jpg",
            //url: toUrl("Fintech y Compliance: Regulador de EE.UU fiscaliza fusión de Banco Fintech y declara preocupación por falta de normativa"),
            firstParagraph: `De acuerdo con las últimas informaciones de LAVCA más del 30% de los fondos invertidos en Latinoamérica tienen
            como destino empresas de fintech, liderando en montos de inversión VC en la región. Este aumento en la creación y
            financiamiento de empresas de corte tecnológico financiero es una tendencia mundial que se replica en
            Chile, donde se anticipa que anticipa una pronta aprobación de su ley fintech.`,
            content: `
    
Desde el mundo startup hay mucho que decir de las fintech y cómo éstas traen innovaciones y disrupción a un
mercado tradicional como la banca. La pregunta que queda es cómo estas empresas están enfrentando sus necesidades
de compliance y qué efectos pueden tener.
    
Un ejemplo, en Julio de 2021 en USA, Blue Ridge Bank (BRB) y FVCBankcorp (FVC) anunciaron su fusión. En Noviembre de 2021 BRB anunció que 
la contraloría de la moneda (Office of the Comptroller of the Currency o OCC), había identificado materias regulatorias que eran preocupantes
y que impactarían la fusión. En agosto de 2022 BRB anunció que había llegado a acuerdo con la OCC (EX-10.1 ) donde entre otras 
cosas se le pedía revisar sus políticas de Lavado de Activos y Financiamiento del Terrorismo. Aparentemente las compras de BRB de diversas 
Fintech le pasaron la cuenta en este proceso de fusión, lo que además le costó una potencial fusión.
    
En esta misma línea la semana pasada, la misma OCC declaró que, de no haber regulación más estricta, las fintech podían llevar al mercado 
a una crisis ( U.S. bank regulator warns of crisis risk from fintech proliferation ). Estas señales las debemos mirar con cuidado. 
En Chile ya tenemos experiencia en la relación entre bancos y fintechs. Hace pocas semanas los bancos llegaron a acuerdo con algunas plataformas 
de criptomonedas, fundando justamente en la implementación de medidas que prevengan el lavado de activos y financiamiento del terrorismo.
    
Así, el mensaje es claro, toda fintech debería tener en su base y como pilar estratégico una política robusta de AML/FT, es lo que los bancos, 
los reguladores y los inversionistas estarán analizando de cerca al momento de relacionarse con las fintech. El costo de no hacerlo puede 
implicar afectar la continuidad misma del negocio.
`
        },
        EN:{
            title: "Fintech and Compliance: U.S. Regulator Scrutinizes Fintech Bank Merger and Expresses Concern Over Lack of Regulation",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/fintec-compliance-01-small.jpg",
            //url: toUrl("Fintech y Compliance: Regulador de EE.UU fiscaliza fusión de Banco Fintech y declara preocupación por falta de normativa"),
            firstParagraph: `According to the latest information from LAVCA, over 30% of the funds invested in Latin America are aimed at fintech companies, leading in VC investment amounts in the region. This increase in the creation and financing of financial technology companies is a global trend that is replicated in Chile, where the approval of its fintech law is anticipated soon.`,
            content: `
    
From the startup world, there is much to say about fintechs and how they bring innovation and disruption to a traditional market like banking. The question that remains is how these companies are facing their compliance needs and what effects they may have.

For example, in July 2021 in the USA, Blue Ridge Bank (BRB) and FVCBankcorp (FVC) announced their merger. In November 2021, BRB announced that the Office of the Comptroller of the Currency (OCC) had identified regulatory matters that were concerning and that would impact the merger. In August 2022, BRB announced that it had reached an agreement with the OCC (EX-10.1), where, among other things, it was asked to review its Anti-Money Laundering and Terrorist Financing policies. Apparently, BRB's purchases of various Fintechs took their toll in this merger process, which also cost them a potential merger.
            
In the same vein, last week, the same OCC stated that, without stricter regulation, fintechs could lead the market to a crisis (U.S. bank regulator warns of crisis risk from fintech proliferation). These signals should be carefully considered. In Chile, we already have experience in the relationship between banks and fintechs. A few weeks ago, the banks reached an agreement with some cryptocurrency platforms, precisely based on the implementation of measures to prevent money laundering and terrorist financing.
            
Thus, the message is clear, every fintech should have as its base and strategic pillar a robust AML/FT policy, which is what banks, regulators, and investors will be closely analyzing when relating to fintechs. The cost of not doing so could affect the very continuity of the business.
`
        } 
    },
    {
        url: toUrl("Segundo Trimestre de Inversión VC en Latinoamérica: continúa baja pero etapas tempranas mantienen optimismo"),
        ES:{
            title: "Segundo Trimestre de Inversión VC en Latinoamérica: continúa baja pero etapas tempranas mantienen optimismo",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Segundo Trimestre de Inversión VC en Latinoamérica: continúa baja pero etapas tempranas mantienen optimismo"),
            firstParagraph: `Agencias de noticias nos indican que el el primer trimestre del 2022 en Latinoamérica se levantó menos capital que en 
            el último trimestre del 2021. Adicionalmente En mayo de 2022, Y-Combinator, una de las aceleradoras más importantes 
            de USA, indicó que dentro de los próximos 6 a 12 meses puede ser uno de los momentos más difíciles para lograr dicho 
            objetivo.`,
            content: `
    
El segundo trimestre del 2022 cimentó esta tendencia a la baja, con especial foco en series B. Así lo indica crunchbase:
    
Desde una mirada optimista las empresas de etapas tempranas mantienen niveles aceptables de inversión, siendo una de las 
áreas menos afectadas por esta baja. Esto puede ser interpretado como una mirada optimista a largo plazo, donde temáticas
de oferta pública o levantamientos de cientos de millones de dólares todavía se ven como algo a determinarse más adelante.
    
¿Qué significa esto para las startups que buscan levantar capital?
Es nuestra experiencia que los fondos de inversión van a ser más estrictos en su análisis de las empresas, sin importar 
su etapa. Es por esto que las empresas que se encuentran en rondas semilla y etapas tempranas deben mirar con especial 
atención los siguientes elementos:
    
- Tracción: En etapas tempranas, los emprendedores deben demostrar tracción a través de sus ideas, el producto y un plan 
    estratégico de venta. En el caso de etapas tempranas debe mostrarse tracción que se tiene a la fecha, una línea de tiempo 
    con la proyección de dicha tracción y forma en que los fondos permitirán aumentar ventas e ingresar a nuevos mercados.
        
- Presupuesto: En cualquier etapa es importante demostrar capacidad de cumplir objetivos de comerciales, tanto de venta, 
    como de costos, así como una mirada a largo plazo de la sustentabilidad del negocio.
    
- Equipo: Los fundadores deben ser capaces de sumar aquellas capacidades que no tengan y que sean necesarias 
    estratégicamente.
        
- Cap table: mantener su capital y deuda en orden es un elemento previo a cualquier inversión. Los fundadores tendrán que 
    tener especial cuidado al momento de desarrollar sus planes de incentivos que usen de opciones de acciones 
    (Employee Stock Option Plans o ESOP).
    
En conclusión, a pesar de un ajuste en los montos de inversión, las etapas semilla y tempranas todavía han logrado 
esquivar la caída más profunda de los VC en latinoamérica. Dicho esto, los fondos de inversión están mirando con cuidado 
como alocan su capital, buscando empresas con capacidad de demostrar su viabilidad en el mundo real más allá de su pitch 
deck o presentación inicial.    
`
        },
        EN:{
            title: "Latin American VC Investment in the Second Quarter: Decline Continues but Early Stages Remain Optimistic",
            author: "ANGUITAOSORIO",
            date: "2022-09-13",
            imagesrc:"/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Segundo Trimestre de Inversión VC en Latinoamérica: continúa baja pero etapas tempranas mantienen optimismo"),
            firstParagraph: `News agencies report that in the first quarter of 2022, less capital was raised in Latin America compared to the last quarter of 2021. Additionally, in May 2022, Y-Combinator, one of the most important accelerators in the USA, indicated that the next 6 to 12 months could be one of the most challenging times to achieve this goal.`,
            content: `
    
The second quarter of 2022 cemented this downward trend, with a special focus on Series B, as indicated by Crunchbase.

From an optimistic point of view, early-stage companies maintain acceptable levels of investment, being one of the areas least affected by this decline. This can be interpreted as a long-term optimistic outlook, where themes of public offerings or raising hundreds of millions of dollars are still seen as something to be determined later.
            
What does this mean for startups looking to raise capital?
In our experience, investment funds are going to be more stringent in their analysis of companies, regardless of their stage. Therefore, companies that are in seed rounds and early stages should pay special attention to the following elements:
            
- Traction: In early stages, entrepreneurs must demonstrate traction through their ideas, the product, and a strategic sales plan. In early stages, current traction should be shown, along with a timeline of projected traction and how funds will allow increasing sales and entering new markets.
            
- Budget: At any stage, it's important to demonstrate the ability to meet commercial objectives, both in terms of sales and costs, as well as a long-term view of the business's sustainability.
            
- Team: Founders must be able to add the capabilities they lack and that are strategically necessary.
            
- Cap table: Keeping capital and debt in order is a prerequisite for any investment. Founders will have to be particularly careful when developing their incentive plans using stock options (Employee Stock Option Plans or ESOPs).
            
In conclusion, despite an adjustment in investment amounts, the seed and early stages have still managed to avoid the deepest fall of VCs in Latin America. That said, investment funds are carefully considering how to allocate their capital, looking for companies with the ability to demonstrate their viability in the real world beyond their pitch deck or initial presentation.    
` 
        }

    },
    {
        url: toUrl("El compliance ante la nueva Ley de Delitos Económicos Una visión práctica"),
        ES:{
            title: "El compliance ante la nueva Ley de Delitos Económicos Una visión práctica",
            author: "Anguita Osorio",
            date: "2023-06-06",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("El compliance ante la nueva Ley de Delitos Económicos Una visión práctica"),
            firstParagraph: "El compliance ante la nueva Ley de Delitos Económicos - Una visión práctica",
            youtubeUrl: "https://www.youtube.com/watch?v=jbzA6ecBalQ&ab_channel=AnguitaOsorio",
            content: `
La incorporación de 200 nuevos delitos, la mayor responsabilidad penal de directores y ejecutivos, 
junto a  los requisitos adicionales a los modelos de prevención del delito y sanciones que apuntan 
al corazón de la empresa. En este escenario, el gran desafío de hoy es repensar el compliance que vendrá 
con la nueva Ley de Delitos Económicos.
`
        },
        EN:{
            title: "Compliance in the Face of the New Economic Crimes Law: A Practical View",
            author: "Anguita Osorio",
            date: "2023-06-06",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("El compliance ante la nueva Ley de Delitos Económicos Una visión práctica"),
            firstParagraph: "Compliance in the Face of the New Economic Crimes Law - A Practical View",
            youtubeUrl: "https://www.youtube.com/watch?v=jbzA6ecBalQ&ab_channel=AnguitaOsorio",
            content: `
The incorporation of 200 new offenses, increased criminal liability for directors and executives, along with additional requirements for crime prevention models and sanctions that target the core of the company. In this scenario, the great challenge today is to rethink the compliance that will come with the new Economic Crimes Law.
`
        }
    },
    {
        url: toUrl("Aproximación desde el Compliance a los Nuevos Delitos Ambientales"),
        ES:{
            title: "Aproximación desde el Compliance a los Nuevos Delitos Ambientales",
            author: "Anguita Osorio",
            date: "2023-07-03",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Aproximación desde el Compliance a los Nuevos Delitos Ambientales"),
            firstParagraph: "El pasado 30 de Junio realizamos nuestro Webinar sobre la nueva Ley de Delitos Económicos y Medioambientales",
            youtubeUrl: "https://www.youtube.com/watch?v=L6TnFiouPLA&ab_channel=AnguitaOsorio",
            content: `
La nueva Ley de Delitos Económicos y Medioambientales no sólo crea un sistema de delitos ambientales, 
sino que hace responsable a la persona jurídica por ellos ¿Cómo abordar esta nueva realidad en los modelos 
de compliance?`
        },
        EN:{
            title: "Approaching New Environmental Crimes from a Compliance Perspective",
            author: "Anguita Osorio",
            date: "2023-07-03",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Aproximación desde el Compliance a los Nuevos Delitos Ambientales"),
            firstParagraph: "On June 30th, we held our Webinar on the new Law of Economic and Environmental Crimes",
            youtubeUrl: "https://www.youtube.com/watch?v=L6TnFiouPLA&ab_channel=AnguitaOsorio",
            content: `
The new Law of Economic and Environmental Crimes not only creates a system of environmental crimes but also holds the legal entity responsible for them. How should this new reality be addressed in compliance models?`  
        }
    },
    {
        url: toUrl("Nueva Ley de Delitos Económicos: una oportunidad para las empresas"),
        ES:{
            title: "Nueva Ley de Delitos Económicos: una oportunidad para las empresas",
            author: "Jaime Winter y Eduardo Anguita",
            date: "2023-08-21",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Nueva Ley de Delitos Económicos: una oportunidad para las empresas"),
            firstParagraph: "La reciente publicada Ley de Delitos Económicos, presenta un escenario desafiliante para los modelos de compliance, con un aumento de los delitos que hacen penalmente responsables a las empresas y que endurece la responsabilidad de la empresa y sus ejecutivos.",
            content: `
Sin embargo, a la vez ofrece una oportunidad para que los modelos de
prevención realmente puedan hacerse cargo de manera más completa y profunda de los riesgos de las empresas. ¿Cómo enfrentar
esto en los 12 meses de vacancia que entregó la ley para su entrada en vigencia respecto de las personas jurídicas?
    
En primer lugar, ante la dispersión normativa que hace imposible que cada ejecutivo y trabajador conozca la totalidad de los delitos,
resulta fundamental que las organizaciones enfoquen sus esfuerzos en el fortalecimiento de la cultura corporativa. Esto no es solo una
idea etérea sin concreción práctica: se requiere un levantamiento de riesgos de cultura corporativa como parte del levantamiento de
riesgos penales y el diseño de un plan de trabajo que incluya aspectos de la comuniación interna e interverciones concretas.
    
Asimismo, los nuevos riesgos penales, en buena medida, ya eran riesgos regulatorios con importantes consecuencias reputacionales, legales
y monetarias para la empresa. En ese sentido, que no estuvieran ya en un modelo de cumplimiento normativo era una oportunidad perdida por 
la empresa, algo que hoy puede enmendarse.
    
Otro aspecto relevante es que los controles deben dejar de estar asociados a un delito concreto y servir para prevenir múltiples delitos.
Se termina la época de las matrices de riesgo en que hay una "hoja" por delito, sino que se deberán identificar actividades en la que existen
riesgos de múltiples delitos y controles que servirán para prevendir diversas infracciones.
    
La ley ha puesto especial interés que la empresa se mire a si misma y determine realmente a qué infracciones normativas está expuesta. En el
levantamiento de riesgos será fundamental esa mirada, que deberá ir acompañada con la comprensión de que no todas las áreas de la empresa 
están expuestas a los mismos riegos.
    
Muchas veces nos tocó escuchar "acabamos de certificar el modelo de prevención del delito, pero acaban de salir delitos nuevos, asi que 
ahora necesito actualizarlo". Las certificaciones daban una falsa idea de seguridad y de que el compliance era un esfuerzo de un periodo 
acotado. Hoy, con la idea de la asesoría externa debe entenderse el compliance como una tarea constante y permanente que debe estar 
preparada para cambios legales, juriprudenciales y regulatorios, así como cambios en la empresa y en el mercado.
    
Las empresas deberán volver a mirar al oficial de cumplimiento y asegurarse de que tenga las caracteristicas personales adecuadas, es decir,
que tenga los recursos, las capacidades técnicas, el liderazgo y la ascendencia adecuada sobre el resto de la empresa. Pero, a la vez, es 
necesario entender que la función de compliance hoy estará repartida en distintas figuras dentro de la empresa.
    
Estando hoy frente a la revolución de la inteligencia artificial, parece absurdo no considerar a la tecnología como un elemento fundamental
de apoyo al modelo de compliance. Sin embargo, deben pensarse las soluciones tecnológicas de forma que se conecten con las necesidades de la
empresa y su complejidad actual.
    
Puedes ver la columna publicada en PULSO haciendo click en el siguiente enlance: [Haz click aquí](https://kiosco.latercera.com/reader/21-08-2023-pulso/).
`
        },
        EN:{
            title: "New Economic Crimes Law: An Opportunity for Businesses",
            author: "Jaime Winter y Eduardo Anguita",
            date: "2023-08-21",
            imagesrc: "/images/blog/segundo-trimestre-vc-latam-01-small.jpg",
            //url: toUrl("Nueva Ley de Delitos Económicos: una oportunidad para las empresas"),
            firstParagraph: "The recently published Economic Crimes Law presents a challenging scenario for compliance models, with an increase in crimes that hold companies criminally responsible and that harden the liability of the company and its executives.",
            content: `
However, it also offers an opportunity for prevention models to more completely and deeply address the risks faced by companies. How to tackle this in the 12-month grace period given by the law for its entry into force concerning legal entities?

Firstly, given the normative dispersion that makes it impossible for each executive and worker to know all the crimes, it is fundamental that organizations focus their efforts on strengthening corporate culture. This is not just an ethereal idea without practical concreteness: it requires a corporate culture risk assessment as part of the criminal risk assessment and the design of a work plan that includes aspects of internal communication and concrete interventions.
            
Also, the new criminal risks, to a large extent, were already regulatory risks with significant reputational, legal, and monetary consequences for the company. In that sense, not having them already in a regulatory compliance model was a missed opportunity for the company, something that can be rectified today.
            
Another important aspect is that controls must stop being associated with a specific crime and serve to prevent multiple crimes. The era of risk matrices with a "sheet" per crime is over, and activities must be identified in which there are risks of multiple crimes and controls that will serve to prevent various infringements.
            
The law has put special interest in the company looking at itself and truly determining what regulatory infringements it is exposed to. In risk assessment, this perspective will be fundamental and must be accompanied by the understanding that not all areas of the company are exposed to the same risks.
            
We often heard, "we just certified the crime prevention model, but new crimes just came out, so now I need to update it". Certifications gave a false sense of security and the idea that compliance was an effort of a limited period. Today, with the idea of external consultancy, compliance must be understood as a constant and permanent task that must be prepared for legal, jurisprudential, and regulatory changes, as well as changes in the company and the market.
            
Companies must look again at the compliance officer and ensure that they have the right personal characteristics, that is, that they have the resources, the technical capabilities, the leadership, and the proper influence over the rest of the company. But, at the same time, it is necessary to understand that the compliance function today will be distributed among different figures within the company.
            
Being today in front of the artificial intelligence revolution, it seems absurd not to consider technology as a fundamental element of support for the compliance model. However, technological solutions must be thought of in a way that connects with the needs of the company and its current complexity.
            
You can read the published column in PULSO by clicking on the following link:[Click here](https://kiosco.latercera.com/reader/21-08-2023-pulso/).
`
        },
        url: toUrl("CMF dicta nueva normativa para prestadores de servicios financieros de la ley fintech"),
        ES:{
            title: "CMF dicta nueva normativa para prestadores de servicios financieros de la ley Fintech.",
            author: "ANGUITAOSORIO",
            date: "2024-02-13",
            imagesrc: "/images/blog/fintec-normativa.jpg",
            //url: toUrl("Nueva Ley de Delitos Económicos: una oportunidad para las empresas"),
            firstParagraph: "El pasado viernes 12 de enero de 2024, la Comisión Para el Mercado Financiero (“CMF”) ha emitido la Norma de Carácter General N° 502 (“NCG N° 502”), que regula el registro, autorización y obligaciones de los prestadores de servicios financieros de la Ley Fintech. Esta NGC deroga las NCG 493 y 494",
            content: `
Tras la promulgación de la Ley N° 21.521, conocida como Ley Fintech, el 4 de enero de 2023, la Comisión para el Mercado Financiero (CMF) ha desarrollado normativas para su implementación efectiva. El 12 de enero de 2024, se publicó la Norma de Carácter General N° 502 (NCG N° 502), ofreciendo mayor claridad a los prestadores de servicios financieros tecnológicos. Esta norma amplía el enfoque regulatorio más allá de las asesorías de inversión, incluyendo a todos los actores del sector financiero tecnológico, marcando un avance significativo desde la introducción de la Ley Fintech.

La NCG N° 502 es aplicable a quienes se dediquen profesionalmente a los servicios de asesoría crediticia, custodia de instrumentos financieros, enrutamiento de órdenes, intermediación de instrumentos financieros, asesoría de inversión, plataformas de financiamiento colectivo y sistemas alternativos de transacción, presenta, entre otras, las siguientes novedades:

1. De acuerdo a la Ley Fintech, los prestadores de servicios financieros basados en tecnología, y no correspondan a otras categorías ya existentes deben estar inscritos en el Registro de Prestadores Financieros de la CMF. Al respecto, la NCG N° 502 estableció los requisitos específicos que deben cumplir las solicitudes de inscripción en el Registro, indicó algunas excepciones a lo anterior, como exceptuar de algunos requisitos a ciertos prestadores de servicios que tengan como destinatarios a inversionistas calificados, entre otras novedades.

2. Un avance fundamental se dio respecto de la autorización necesaria para prestar servicios regulados por la Ley Fintech, puesto que con anterioridad a la la NCG N° 502, esta materia solo estaba regulada respecto de los prestadores de servicios de asesoría de inversión, mientras que ahora se han regulado los requisitos respecto de todos los prestadores de servicios financieros basados en tecnología, y se ha eximido de la obligación de solicitar la mencionada autorización a ciertos prestadores cuyos destinatarios son inversionistas calificados.

3. Se han regulado por primera vez las exigencias de capital y garantías, respecto de quienes presten los servicios de intermediación de instrumentos financieros y de custodia de instrumentos financieros, normas también aplicables a quienes presten el servicio de enrutamiento de órdenes, solo respecto de las garantías. El grado de exigencia regulatoria en estas materias viene dado por el volumen de los negocios del prestador, para cuyo efecto la CMF estableció tres bloques en los cuales puede categorizarse a cada prestador, los cuales fueron determinados de acuerdo al número de clientes activos, transacciones promedio, ingresos en los últimos 12 meses y activos custodiados.

4. Cabe señalar que la NCG N° 502 también se refirió minuciosamente a normas relacionadas con obligaciones de información, acreditación de capacidad operacional, actividades inherentes a los servicios regulados por la Ley Fintech, y exigencias en materias de gobierno corporativo y gestión de riesgos.

La NCG N° 502 entrará en vigencia a contar del día 3 de febrero de 2024, los prestadores de servicios de asesoría de inversión deberán presentar sus solicitudes de inscripción y autorización antes de ese plazo; asimismo, quienes se dedican al mencionado giro y ya se encuentren inscritos en el Registro deberán ajustarse a la nueva normativa y remitir los antecedentes requeridos para la inscripción y autorización antes del 3 de agosto de 2024. Los prestadores de los demás servicios regulados por la Ley Fintech deberán presentar sus respectivas solicitudes antes del 3 de febrero de 2025.

De este modo, sin perjuicio de que ya ha transcurrido un año desde la publicación de la Ley Fintech, con la la NCG N° 502 comenzará a llevarse a la realidad de manera orgánica el proceso de implementación necesario para que se promueva la competencia e inclusión financiera a través de la innovación y tecnología en la prestación de servicios financieros.

            `
        },
        EN:{
            title: "CMF issues new regulations for financial service providers under the Fintech Law",
            author: "ANGUITAOSORIO",
            date: "2024-02-13",
            imagesrc: "/images/blog/fintec-normativa.jpg",
            //url: toUrl("Nueva Ley de Delitos Económicos: una oportunidad para las empresas"),
            firstParagraph: "On Friday, January 12, 2024, the Financial Market Commission ('CMF') issued General Rule No. 502 ('NCG No. 502'), regulating the registration, authorization, and obligations of financial service providers under the Fintech Law. This NCG repeals NCGs 493 and 494.",
            content: `
Following the enactment of Law No. 21.521, known as the Fintech Law, on January 4, 2023, the Financial Market Commission (CMF) has developed regulations for its effective implementation. On January 12, 2024, General Rule No. 502 (NCG No. 502) was published, providing greater clarity to technology-based financial service providers. This rule expands the regulatory focus beyond investment advisory services, including all actors in the financial technology sector, marking a significant advance since the introduction of the Fintech Law.

NCG No. 502 applies to those who professionally provide credit advisory services, custody of financial instruments, order routing, intermediation of financial instruments, investment advisory, crowdfunding platforms, and alternative transaction systems. Among other innovations, it introduces the following:

According to the Fintech Law, technology-based financial service providers, not belonging to other existing categories, must be registered in the CMF's Financial Service Providers Registry. In this regard, NCG No. 502 established specific requirements for registration applications, indicated some exceptions, such as exempting certain service providers from some requirements if their clients are qualified investors, among other novelties.

A fundamental advance was made regarding the necessary authorization to provide services regulated by the Fintech Law. Before NCG No. 502, this matter was only regulated for investment advisory service providers. Now, requirements have been regulated for all technology-based financial service providers, and certain providers serving qualified investors are exempt from the obligation to request such authorization.

For the first time, capital and guarantee requirements have been regulated for those providing financial instrument intermediation and custody services. These rules also apply to order routing services, but only regarding guarantees. The regulatory demand in these matters is determined by the provider's business volume, for which the CMF established three categories based on the number of active clients, average transactions, revenue over the last 12 months, and assets under custody.

It's worth noting that NCG No. 502 also detailed regulations related to information obligations, operational capacity accreditation, activities inherent to the services regulated by the Fintech Law, and requirements in corporate governance and risk management.

NCG No. 502 will come into effect on February 3, 2024. Investment advisory service providers must submit their registration and authorization applications before this deadline. Those already registered must comply with the new regulations and submit the required documentation for registration and authorization before August 3, 2024. Providers of other services regulated by the Fintech Law must submit their applications before February 3, 2025.

Thus, although a year has passed since the publication of the Fintech Law, the implementation process necessary to promote financial competition and inclusion through innovation and technology in financial services will begin to unfold organically with NCG No. 502.
            `
        }
    }
]

export const newsData = raw_data.map(element => ({url: toUrl(element.title) ,...element}))

