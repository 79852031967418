import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { StyledDiv } from './styles/Cookie.stlyed';

function CookieBanner() {
  const [cookies, setCookie] = useCookies(['userAcceptedCookies']);
  const [showBanner, setShowBanner] = useState(!cookies.userAcceptedCookies);
  const { t } = useTranslation(); 

  const acceptCookies = () => {
    setCookie('userAcceptedCookies', true, { path: '/' });
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <StyledDiv>
      <p>{t('cookieBanner.message')}</p>
      <button onClick={acceptCookies}>{t('cookieBanner.accept')}</button>
    </StyledDiv>
  );
}

export default CookieBanner;
