import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../fonts/font-awesome/css/font-awesome.min.css";
import { StyledNav } from './styles/Nav.styled';
import { StyledNavDropdown } from './styles/NavDropdown.styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AONav() {
    const { t, i18n } = useTranslation();
    const [languageLabel, setLanguageLabel] = useState('English');

    useEffect(() => {
        i18n.changeLanguage('es');
    }, [i18n]); 

    const toggleLanguage = () => {
        const newLang = i18n.language === 'es' ? 'en' : 'es';
        i18n.changeLanguage(newLang);
        setLanguageLabel(newLang === 'es' ? 'English' : 'Español');
    };

    return (
        <Navbar fixed="top" expand="md" style={{ background: "white" }}>
            <Container fluid style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Navbar.Brand as={Link} to="/">
                    <img style={{ width: '160px' }} src={require('../images/index/consulting/logo.png')} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <StyledNav className="ms-auto">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/Nosotros">{t('navbar.nosotros')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/Equipo">{t('navbar.equipo')}</Nav.Link>
                        </Nav.Item>

                        <StyledNavDropdown title={t('navbar.areasDePractica')} id="navbarScrollingDropdown">
                            <NavDropdown.Item disabled={true} to="/Corporativo">{t('navbar.area')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Corporativo">{t('navbar.corporativo')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/VC">{t('navbar.capitalDeRiesgo')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Compliance">{t('navbar.cumplimientoNormativo')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/next/leyDelitosEconomicos.html">{t('navbar.leyDelitosEconomicos')}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item disabled={true} to="/Corporativo">{t('navbar.practicasYSolucionesAvanzadas')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Legalops">{t('navbar.operacionesLegales')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Legaltech">{t('navbar.consultoriaLegalTecnologica')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Outsourcing">{t('navbar.externalizacionDeProcesosLegales')}</NavDropdown.Item>
                        </StyledNavDropdown>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/Noticias">{t('navbar.noticias')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/Contacto">{t('navbar.contacto')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} onClick={toggleLanguage}>
                                {languageLabel}
                            </Nav.Link>
                        </Nav.Item>
                    </StyledNav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AONav;
