import styled from "styled-components";
import {Button} from "react-bootstrap";

export const StyledContactButton = styled(Button)`
    font-family: 'nexa-bolduploaded_file';
    background-color:#71d8db;
    border-color:#71d8db;
`;

export const StyledServicesHeader = styled.h2`
    text-align:center;
    margin-bottom:30px;
`;
