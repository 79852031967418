import styled from "styled-components";

export const StyledDiv = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: grey;
    color: white;
    z-index: 9999;
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
`;
