import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import { StyledMarkdown } from "./styles/Markdown.styled";

function TwoColumnLeftImg({ title, subtitle, content, src }) {
  return (
    <>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "150px", marginTop: "80px", backgroundColor: "#71d8db"}}>
        <h1 style={{color: "white", fontSize: "28px", fontWeight: "700"}}>{title}</h1>
        <p style={{color: "white", fontSize: "18px", fontWeight: "400"}}>{subtitle}</p>
      </div>
      <Container fluid style={{border: 0, padding: 0}}>
        <Row>
          <Col lg={6}>
            <img src={src} alt={title} style={{overflow: "hidden", width: "100%", height: "100%", objectFit: "cover", margin: "0", maxHeight: "1000px", maxWidth: "auto" }}/>
          </Col>
          <Col lg={6} style={{padding: "50px 5% 0 5%", marginBottom: "20px"}}>
            <StyledMarkdown>
              <ReactMarkdown>{content}</ReactMarkdown>
            </StyledMarkdown>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TwoColumnLeftImg;