import React, { useState } from 'react';
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import { StyledMarkdown } from "./styles/Markdown.styled";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useForm } from 'react-hook-form';

function TwoColumnLeftImgContact(props) {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase();
    moment.locale(currentLanguage === 'es' ? 'es' : 'en');

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = async (data) => {
        const url = "https://3mjt2ckr82.execute-api.us-east-1.amazonaws.com/contact"; // URL de la API HTTP en API Gateway
        const formData = {
            email: data.email,
            name: data.name,
            company: data.company,
            jobTitle: data.jobTitle,
            phone: data.phone
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error en la solicitud: ${errorText}`);
            }

            alert('¡Gracias por contactarnos!');
            reset();
            handleClose();
        } catch (error) {
            console.error('Error:', error);
            alert(`Hubo un problema con tu solicitud de contacto: ${error.message}`);
        }
    };

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "150px",
                marginTop: "80px",
                backgroundColor: "#71d8db"
            }}>
                <h1 style={{ color: "white", fontSize: "28px", fontWeight: "700" }}>{props.title}</h1>
                <p style={{ color: "white", fontSize: "18px", fontWeight: "400" }}>{props.subtitle}</p>
            </div>
            <Container fluid style={{ border: 0, padding: 0 }}>
                <Row>
                    <Col lg={6}><img src={props.src} alt={"left"} style={{
                        overflow: "hidden",
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        margin: "0",
                        maxHeight: "600px",
                        maxWidth: "auto"
                    }} /></Col>
                    <Col lg={6} style={{ padding: "50px 5% 0 5%" }}>
                        <StyledMarkdown>
                            <ReactMarkdown>{props.content}</ReactMarkdown>
                        </StyledMarkdown>
                        
                    </Col>
                    <Button 
                        style={{ backgroundColor: '#71d8db', borderColor: '#71d8db', marginTop: '20px' }}
                        onClick={handleShow}>
                        {t('areasPractica.contactanos')}
                    </Button>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('contactForm.contactUs')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>{t('contactForm.name')}</label>
                            <input
                                type="text"
                                name="name"
                                placeholder={t('contactForm.name')}
                                {...register('name', { required: true })}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            />
                            {errors.name && <div className="invalid-feedback">{t('contactForm.requiredField')}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('contactForm.email')}</label>
                            <input
                                type="email"
                                name="email"
                                placeholder={t('contactForm.email')}
                                {...register('email', { required: true })}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <div className="invalid-feedback">{t('contactForm.requiredField')}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('contactForm.company')}</label>
                            <input
                                type="text"
                                name="company"
                                placeholder={t('contactForm.company')}
                                {...register('company', { required: true })}
                                className={`form-control ${errors.company ? 'is-invalid' : ''}`}
                            />
                            {errors.company && <div className="invalid-feedback">{t('contactForm.requiredField')}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('contactForm.jobTitle')}</label>
                            <input
                                type="text"
                                name="jobTitle"
                                placeholder={t('contactForm.jobTitle')}
                                {...register('jobTitle', { required: true })}
                                className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                            />
                            {errors.jobTitle && <div className="invalid-feedback">{t('contactForm.requiredField')}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('contactForm.phone')}</label>
                            <input
                                type="text"
                                name="phone"
                                placeholder={t('contactForm.phone')}
                                {...register('phone', { required: true })}
                                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                            />
                            {errors.phone && <div className="invalid-feedback">{t('contactForm.requiredField')}</div>}
                        </div>
                        <Button 
                            style={{  paddingTop: "10px", marginTop: "20px" }} 
                            variant="primary" 
                            type="submit">
                            {t('contactForm.submit')}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
            
        </>
    );
}

export default TwoColumnLeftImgContact;
