import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import MarkdownRender from '../components/MarkdownRender';
import Footer from '../components/Footer';
import { getWebsiteData } from '../data/Website';

const PoliticaDePrivacidad = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase(); // 'ES' o 'EN'

  const politicaContent = getWebsiteData('politica_de_privacidad', currentLanguage);

  return (
    <>
      <AONav />
      <MarkdownRender 
        content={politicaContent.content} 
        title={politicaContent.title}
      />
      <Footer />
    </>
  );
}

export default PoliticaDePrivacidad;
