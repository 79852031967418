import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import { StyledMarkdown } from "./styles/Markdown.styled";
import { StyledServicesDivider } from "./styles/Services.styled";
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es'; 
import { useTranslation } from 'react-i18next';
import { newsData } from '../data/News';

const NewsList = function () {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    moment.locale(currentLanguage === 'en' ? 'en' : 'es');

    const sortedNewsData = newsData.slice().sort((a, b) => {
        const dateA = moment(a.ES.date, "YYYY-MM-DD");
        const dateB = moment(b.ES.date, "YYYY-MM-DD");
        return dateA.isBefore(dateB) ? 1 : -1;
    });

    return (
        <div style={{ marginTop: "100px" }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "150px",
                marginTop: "80px",
                backgroundColor: "#71d8db"
            }}>
                <h1 style={{ color: "white", fontSize: "36px", fontWeight: "700" }}>{t('newsList.title')}</h1>
            </div>
            {sortedNewsData.map((newsItem) => {
                const newsContent = newsItem[currentLanguage.toUpperCase()] || newsItem['ES']; 
                return (
                    <Container key={newsItem.url} style={{ marginTop: "30px" }}>
                        <Link to={"/Noticias/".concat(newsItem.url)} style={{ textDecoration: "none", color: "inherit" }}>
                            <Row style={{ cursor: "pointer" }}>
                                <Col lg={3}>
                                    <img src={newsContent.imagesrc}
                                        alt={"left"} style={{
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover"
                                        }} />
                                </Col>
                                <Col lg={9}>
                                    <Row style={{ marginLeft: "1px" }}>{moment(newsContent.date).format('LL')}</Row>
                                    <h2>{newsContent.title}</h2>
                                    <StyledMarkdown>
                                        <ReactMarkdown>{newsContent.firstParagraph.split(' ').slice(0, 50).join(' ').concat(' ...')}</ReactMarkdown>
                                    </StyledMarkdown>
                                </Col>
                            </Row>
                        </Link>
                        <StyledServicesDivider />
                    </Container>
                );
            })}
        </div>
    );
}


export const toUrl = function (string) {
    return (
        string.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll('-', '')
            .replace(/\s\s+/g, ' ')
            .replaceAll(' ', '-')
            .replace(/[.,/#!$%^&*;:{}=_`~()]/g, "")
            .toLowerCase()
    );
}

export default NewsList;
