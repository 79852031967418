import React from "react";
import ReactMarkdown from "react-markdown";
import { StyledMarkdown } from "./styles/Markdown.styled";

function MarkdownRender(props) {
  return <>
        <div style={{display:"flex",
            flexDirection:"column",
            alignItems: "center",
            justifyContent:"center",height:"150px",marginTop:"80px",backgroundColor:"#71d8db"}}>
          <h1 style={{color:"white",fontSize:"32px",fontWeight:"700"}}>{props.title}</h1>
           <p style={{color:"white",fontSize:"18px",fontWeight:"400"}}>{props.subtitle}</p>
        </div>
    <div style={{ padding: "30px", margin: "0 auto", maxWidth: "800px", textAlign: "justify"}}>
      <StyledMarkdown>
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </StyledMarkdown>
    </div>
  </>
}

export default MarkdownRender;

