import React from "react";
import AONav from "../components/Navbar";
import Footer from "../components/Footer";
import TwoColumnLeftImg from "../components/TwoColumnLeftImg";
import LinkedInIcon from "../images/icons/linkedin-32x32.png";
import { useTranslation } from 'react-i18next';
import { getWebsiteData } from '../data/Website';


const AM = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; 
    const id = "jt_cuevas"; 
  
    const contentData = getWebsiteData(id, currentLanguage.toUpperCase());
  
    return (
      <>
        <AONav />
        <TwoColumnLeftImg 
          content={contentData.content}
          src={contentData.image}
          title={contentData.title}
          subtitle={contentData.subtitle}
        />
        <Footer />
      </>
    );
  };
  
  export default AM;