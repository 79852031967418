// Practica.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import TwoColumnRightImgAndContactButton from '../components/TwoColumnRightImgAndContactButton';
import Footer from '../components/Footer';
import { getWebsiteData } from '../data/Website';

const Practica = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language ? i18n.language.toUpperCase() : "ES";

  const practicaContent = getWebsiteData(props.id, currentLanguage);

  if (!practicaContent) {
    // Manejar caso de datos no disponibles
    return <div>No se encontraron datos para esta práctica.</div>;
  }

  return (
    <>
      <AONav />
      <TwoColumnRightImgAndContactButton 
        content={practicaContent.content}
        src={practicaContent.image}
        title={practicaContent.title}
      />
      <Footer />
    </>
  );
};

export default Practica;
