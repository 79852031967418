import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export const StyledServicesDivider = styled.hr`
    margin-right:75%;
    width:25%;
    margin-bottom:30px; 
    border:2px solid #71d8db;
    opacity:100;
`;

export const StyledServicesHeader = styled.h2`
    text-align:center;
    margin-bottom:30px;
`;

export const StyledRowServices = styled(Row)`
    padding:30px;
    text-align:center;
    media (max-width: 768px) {
        padding:50px;
    }
    media (max-width: 576px) {
        padding:30px;
    }
    media (max-width: 375px) {
        padding:20px;
    }
`;

export const StyledLink = styled(Link)`
    text-align:left;
    color:#8a8f93;
    text-decoration:none;
    &:hover {
        color:#8a8f93;
        text-decoration:none;
    }
`;