import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import Footer from '../components/Footer';
import TwoColumnLeftImgContact from '../components/TwoColumnLeftImgContact';
import { getWebsiteData } from '../data/Website';

/*
const data = `
**Email**

contacto@anguitaosorio.cl

**Teléfono**

+56 2 2760 3597

**Ubicación**

Los Militares 5620, oficina 905, Comuna de las Condes, Región Metropolitana.
`
*/
const Contacto = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; // 'es' o 'en'
    const id = "contacto"; // Asegúrate de que este ID exista en tus datos
  
    const contentData = getWebsiteData(id, currentLanguage.toUpperCase());
  
    return (
      <>
        <AONav />
        <TwoColumnLeftImgContact 
          content={contentData.content}
          src={contentData.image}
          title={contentData.title}
        />
        <Footer />
      </>
    );
  };
  
  export default Contacto;
