import styled from "styled-components";
import {Linkedin} from "react-bootstrap-icons";
import {Col, Row} from "react-bootstrap";

export const StyledTeamSmallRoundPicDiv = styled.div`
    float: left;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin: 0 20px 20px 0;
    display: block;
    overflow: hidden;
    position: relative;
    background-color:transparent;
`;

export const StyledTeamSmallRoundPicImg = styled.img`
        width:100%;
        display:"block";
`;

export const StyledTeamsDivider = styled.hr`
    margin-right:35%;
     margin-left:35%;
    width:30%;
    margin-bottom:30px; 
    border:2px solid #71d8db;
    opacity:100;
`;

export const StyledAccentedP = styled.p`
    color:#71d8db;
`;

export const StyledLinkedin = styled(Linkedin)`
    color:#71d8db;
    font-size: 1.5em;
    hover: {
        color:#777777;
    }
`;

export const StyledNuestroEquipo = styled(Col)`
    text-align: center;
`;

export const StyledRowTeams = styled(Row)`
    media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    font-size: 1.2em;
`;

