import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import Footer from '../components/Footer';
import TwoColumnLeftImg from '../components/TwoColumnLeftImg';
import { getWebsiteData } from '../data/Website';


const EA = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; 
    const id = "eduardo_anguita"; 
  
    const contentData = getWebsiteData(id, currentLanguage.toUpperCase());
  
    return (
      <>
        <AONav />
        <TwoColumnLeftImg 
          content={contentData.content}
          src={contentData.image}
          title={contentData.title}
          subtitle={contentData.subtitle}
        />
        <Footer />
      </>
    );
  };
  
  export default EA;
