import React from 'react';
import {Col, Row} from "react-bootstrap";
import {StyledServicesDivider, StyledServicesHeader} from "../styles/Services.styled";
import {StyledSection} from "../styles/Section.styled";
import {StyledRowServices, StyledLink} from "../styles/Services.styled";
import { useTranslation } from 'react-i18next'; // Importa useTranslation

const Services = () => {
    const { t } = useTranslation(); // Inicializa useTranslation

    return (
        <StyledSection>
            <Row>
                <Col md={12}><StyledServicesHeader>{t('services.nuestrosServicios')}</StyledServicesHeader></Col>
            </Row>
            <Row>
                <Col md={6} style={{padding:"40px"}}><p>{t('services.descripcion')}</p></Col>
                <Col md={3}>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/Corporativo">{t('services.corporativo')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/VC">{t('services.ventureCapital')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/Compliance">{t('services.compliance')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                </Col>
                <Col md={3}>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/Legalops">{t('services.legalOps')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/Legaltech">{t('services.legalTechConsulting')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                    <StyledRowServices>
                        <Row>
                            <StyledServicesDivider/>
                            <StyledLink to="/Outsourcing">{t('services.legalProcessOutsourcing')}</StyledLink>
                        </Row>
                    </StyledRowServices>
                </Col>
            </Row>
        </StyledSection>
    );
};

export default Services;
