import styled from "styled-components";
import {Nav} from 'react-bootstrap'

export const StyledNav = styled(Nav)`
    @font-face {
        font-family: 'nexa-bolduploaded_file';
        src: url('/src//fonts/font-awesome/fonts/nexa_bold-webfont.woff2') format('woff2'),
             url('/src/fonts/font-awesome/fonts/nexa_bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    *{
        font-family: 'nexa-bolduploaded_file', sans-serif;
        color: #212121;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.30px;
        text-transform: uppercase;
        z-index:100;
    }
  .nav-link {
      color: #000000;
      font-size: 13px;
  }
  .nav-link:hover {
    color: #000000;
  }
  .nav-item {
    position: relative;
  }
  .nav-item::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #71d8db;
      width: 0%;
      content: "";
      height: 2px;
      transition: all 0.3s;
  }
  .nav-item:hover::after {
    width: 100%;
  }
`;
