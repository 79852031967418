import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const Player = ({ className }) => (
    <ReactPlayer
        url="../mountain-lake-1.webm"
        className={className}
        playing
        width="100%"
        height="100%"
        controls={false}
        muted
        loop
    />
);

export const AbsolutelyPositionedPlayer = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const RelativePositionWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  min-height:500px;
  background-color:black;
  border: 1px solid rgba(138 , 143, 147, .5);
`;
