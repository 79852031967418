import React from 'react';

import AONav from "../components/Navbar";
import NewsCarousel from "../components/index/NewsCarousel";
import Services from "../components/index/Services";
import AboutUs from "../components/index/AboutUs";
import Team from "../components/index/Team";
import Footer from "../components/Footer";

const Home = () => (
    <>
        <AONav/>
        <NewsCarousel />
        <Services />
        <AboutUs />
        <Team />
        <Footer />
    </>
);

export default Home;
