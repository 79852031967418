import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    StyledMarkdown, 
    StyledCol, 
    StyledH1, 
    StyledButton, 
    StyledDiv, 
    StyledP,
    StyledColData,
    StyledDivYoutube,
    StyledIframe,
    StyledRow,
    StyledRowButton,
    StyledImg
} from "./styles/Markdown.styled";
import { StyledContactButton } from "./styles/Button.styled";
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import { newsData } from '../data/News';

function NewsColumn(props) {
    const { i18n,t } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase(); 

    useEffect(() => {
        moment.locale(currentLanguage === 'EN' ? 'en-gb' : 'es');
    }, [currentLanguage]);

    let singleNewsData = newsData.find(element => element.url === props.url);

    if (!singleNewsData) {
        console.error('Noticia no encontrada para la URL:', props.url);
        return <div>Noticia no encontrada</div>;
    }
    
    let newsContent = singleNewsData[currentLanguage] || singleNewsData.ES; 

    let currentIndex = newsData.findIndex(element => element.url === props.url);
    let previousIndex = currentIndex > 0 ? currentIndex - 1 : newsData.length - 1;
    let nextIndex = currentIndex < newsData.length - 1 ? currentIndex + 1 : 0;

    return (
        <>
            <StyledDiv>
                <StyledH1>{newsContent.title}</StyledH1>
                <StyledP>{newsContent.subtitle}</StyledP>
            </StyledDiv>
            <Container fluid style={{ border: 0, padding: 0 }}>
                <Row>
                    <StyledCol>
                        <Link style={{ textDecoration: "none", color: "black" }} to="/Noticias">{t('newsColumn.noticias', 'Noticias')}</Link>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol>
                        {moment(newsContent.date).format('LL')} <span>&#183;</span> {newsContent.author}
                    </StyledCol>
                </Row>
                <Row>
                    <StyledColData>
                        <StyledMarkdown>
                            {newsContent.youtubeUrl &&
                                <StyledDivYoutube>
                                    <StyledIframe
                                        src={`https://www.youtube.com/embed/${new URLSearchParams(new URL(newsContent.youtubeUrl).search).get('v')}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></StyledIframe>
                                </StyledDivYoutube>
                            }
                            <ReactMarkdown>{newsContent.firstParagraph}</ReactMarkdown>
                            <StyledImg src={newsContent.imagesrc} alt={newsContent.title} />
                            <ReactMarkdown>{newsContent.content}</ReactMarkdown>
                            <StyledRow>
                                <StyledContactButton href="https://bit.ly/3CbXdfF">{t('newsColumn.contactanos', 'Contáctanos')}</StyledContactButton>
                            </StyledRow>
                        </StyledMarkdown>
                    </StyledColData>
                </Row>
                <StyledRowButton>
                    <Col style={{ textAlign: "initial" }}>
                        <Link to={"/Noticias/" + newsData[previousIndex].url}>
                            <StyledButton style={{ marginLeft: "60px auto" }}>{t('newsColumn.nAnterior', 'Noticia Anterior')}</StyledButton>
                        </Link>
                    </Col>
                    <Col style={{ textAlign: "end" }}>
                        <Link to={"/Noticias/" + newsData[nextIndex].url}>
                            <StyledButton>{t('newsColumn.nSiguiente', 'Siguiente Noticia')}</StyledButton>
                        </Link>
                    </Col>
                </StyledRowButton>
            </Container>
        </>
    );
}

export default NewsColumn;
