import React from 'react';
import { Carousel, CarouselItem, Col, Row } from "react-bootstrap";
import { AbsolutelyPositionedPlayer, RelativePositionWrapper } from "../styles/ReactPlayer.styled";
import {
    StyledNewsBottomDivider,
    StyledNewsBox, StyledNewsCarousel, StyledNewsContainer,
    StyledNewsHeadline,
    StyledNewsRow,
    StyledNewsText,
    StyledNewsTopDivider,
    StyledLink
} from "../styles/HeroNews.styled";
import { Link } from 'react-router-dom';
import { StyledTopSection } from "../styles/Section.styled";
import { newsData } from '../../data/News';
import { useTranslation } from 'react-i18next';

const NewsCarousel = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase();

    let sortedNews = newsData
        .sort((a, b) => new Date(b.ES.date) - new Date(a.ES.date))
        .map((newsItem) => ({
            ...newsItem[currentLanguage] || newsItem['ES'],
            url: newsItem.url  
        }));

    let mainNews = sortedNews.length > 0 ? sortedNews[0] : null;
    let otherNews = sortedNews.length > 1 ? sortedNews.slice(1, 5) : [];

    return (
        <StyledNewsContainer fluid>
            <StyledTopSection>
                <RelativePositionWrapper>
                    <AbsolutelyPositionedPlayer />
                    <StyledNewsRow>
                        <Col md={5} />
                        <Col md={7}>
                            <StyledNewsBox>
                                <StyledNewsTopDivider />
                                <StyledNewsText><p>{i18n.t('carousel.noticias')}</p></StyledNewsText>
                                {mainNews ? (
                                    <div style={{ marginLeft: "2rem" }}>
                                        <StyledNewsHeadline>
                                            <Link style={{ textDecoration: "none", color: "black" }} to={`/Noticias/${mainNews.url}`}>
                                                {mainNews.title}
                                            </Link>
                                        </StyledNewsHeadline>
                                    </div>
                                ) : null}
                                <StyledNewsBottomDivider />
                                <StyledNewsCarousel indicators={false} variant={"dark"}>
                                    {otherNews.map((news) => (
                                        <CarouselItem key={news.title} style={{ textAlign: "left", height: "100px", fontSize: "20px", fontWeight: 900, fontFamily: 'MetaSerifPro-Medi' }}>
                                            <Carousel.Caption style={{ textAlign: "left", bottom: 0, top: "8px", padding: 0 }}>
                                                <Link style={{ textDecoration: "none", color: "black" }} to={`/Noticias/${news.url}`}>
                                                    {news.title}
                                                </Link>
                                            </Carousel.Caption>
                                        </CarouselItem>
                                    ))}
                                </StyledNewsCarousel>
                            </StyledNewsBox>
                        </Col>
                    </StyledNewsRow>
                </RelativePositionWrapper>
            </StyledTopSection>
        </StyledNewsContainer>
    );
};

export default NewsCarousel;
