const termsOfUse_data = {
    'terminos_y_condiciones': {
        'ES': {
            title: "Términos y Condiciones",
            content: `
## Términos y condiciones
¡Bienvenido a anguitaosorio.cl!

Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de Asesorías e Inversiones Celeste SpA, ubicado en https://www.anguitaosorio.cl.

Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes usando anguitaosorio.cl si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.

### Cookies:
El sitio web utiliza cookies para ayudar a personalizar tu experiencia en línea. Al acceder a anguitaosorio.cl, aceptaste utilizar las cookies necesarias.

Una cookie es un archivo de texto que un servidor de páginas web coloca en tu disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a tu computadora. Las cookies se te asignan de forma exclusiva y solo un servidor web en el dominio que emitió la cookie puede leerlas.

Podemos utilizar cookies para recopilar, almacenar y rastrear información con fines estadísticos o de marketing para operar nuestro sitio web. Tienes la capacidad de aceptar o rechazar cookies opcionales. Hay algunas cookies obligatorias que son necesarias para el funcionamiento de nuestro sitio web. Estas cookies no requieren tu consentimiento ya que siempre funcionan. Ten en cuenta que al aceptar las cookies requeridas, también aceptas las cookies de terceros, que podrían usarse a través de servicios proporcionados por terceros si utilizas dichos servicios en nuestro sitio web, por ejemplo, una ventana de visualización de video proporcionada por terceros e integrada en nuestro sitio web.

### Licencia:
A menos que se indique lo contrario, Asesorías e Inversiones Celeste SpA y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en anguitaosorio.cl. Todos los derechos de propiedad intelectual son reservados. Puedes acceder desde anguitaosorio.cl para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.

### No debes:
Copiar o volver a publicar material de anguitaosorio.cl Vender, alquilar o sublicenciar material de anguitaosorio.cl Reproducir, duplicar o copiar material de anguitaosorio.cl Redistribuir contenido de anguitaosorio.cl

Este acuerdo comenzará el fecha presente.

Partes de este sitio web ofrecen a los usuarios la oportunidad de publicar e intercambiar opiniones e información en determinadas áreas. Asesorías e Inversiones Celeste SpA no filtra, edita, publica ni revisa los comentarios antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista ni las opiniones de Asesorías e Inversiones Celeste SpA, sus agentes y/o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica. En la medida en que lo permitan las leyes aplicables, Asesorías e Inversiones Celeste SpA no será responsable de los comentarios ni de ninguna responsabilidad, daños o gastos causados ​​o sufridos como resultado de cualquier uso o publicación o apariencia de comentarios en este sitio web.

Asesorías e Inversiones Celeste SpA se reserva el derecho de monitorear todos los comentarios y eliminar los que puedan considerarse inapropiados, ofensivos o que incumplan estos Términos y Condiciones.

### Garantizas y declaras que:
Tienes derecho a publicar comentarios en nuestro sitio web y tienes todas las licencias y consentimientos necesarios para hacerlo;

Los comentarios no invaden ningún derecho de propiedad intelectual, incluidos, entre otros, los derechos de autor, patentes o marcas comerciales de terceros;

Los comentarios no contienen ningún material difamatorio, calumnioso, ofensivo, indecente o ilegal de otro modo, que sea una invasión de la privacidad.

Los comentarios no se utilizarán para solicitar o promover negocios o actividades comerciales personalizadas o presentes o actividades ilegales.

Por la presente, otorgas a Asesorías e Inversiones Celeste SpA una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de tus comentarios en todas y cada una de las formas, formatos, o medios.

### Hipervínculos a nuestro contenido:
Las siguientes organizaciones pueden vincularse a nuestro sitio web sin aprobación previa por escrito:

- Agencias gubernamentales;
- Motores de búsqueda;
- Organizaciones de noticias;
- Los distribuidores de directorios en línea pueden vincularse a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otras empresas que figuran en la lista;
- y Empresas acreditadas en todo el sistema, excepto que soliciten organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden hacer hipervínculos a nuestro sitio web. Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones o a otra información del sitio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos y/o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.

### Podemos considerar y aprobar otras solicitudes de enlaces de los siguientes tipos de organizaciones:
- fuentes de información de consumidores y/o empresas comúnmente conocidas;
- sitios de la comunidad .com;
- asociaciones u otros grupos que representan organizaciones benéficas;
- distribuidores de directorios en línea;
- portales de Internet;
- firmas de contabilidad, derecho y consultoría;
- y instituciones educativas y asociaciones comerciales.

Aprobaremos las solicitudes de enlace de estas organizaciones si: (a) el enlace no nos haría vernos desfavorablemente a nosotros mismos ni a nuestras empresas acreditadas; (b) la organización no tiene registros negativos con nosotros; (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de Asesorías e Inversiones Celeste SpA; y (d) el enlace está en el contexto de información general de recursos.

Estas organizaciones pueden enlazar a nuestra página de inicio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.

Si eres una de las organizaciones enumeradas en el párrafo 2 y estás interesada en vincularte a nuestro sitio web, debes informarnos enviando un correo electrónico a Asesorías e Inversiones Celeste SpA. Incluye tu nombre, el nombre de tu organización, la información de contacto, así como la URL de tu sitio, una lista de las URL desde las que tienes la intención de vincular a nuestro sitio web y una lista de las URL de nuestro sitio a las que te gustaría acceder. Espera 2-3 semanas para recibir una respuesta.

### Las organizaciones aprobadas pueden hacer hipervínculos a nuestro sitio web de la siguiente manera:
Mediante el uso de nuestro nombre corporativo; o Mediante el uso del localizador uniforme de recursos al que se está vinculando; o Usar cualquier otra descripción de nuestro sitio web al que está vinculado que tenga sentido dentro del contexto y formato del contenido en el sitio de la parte vinculante. No se permitirá el uso del logotipo de Asesorías e Inversiones Celeste SpA u otro material gráfico para vincular sin un acuerdo de licencia de marca comercial.

### Responsabilidad del contenido:
No seremos responsables de ningún contenido que aparezca en tu sitio web. Aceptas protegernos y defendernos contra todas las reclamaciones que se presenten en tu sitio web. Ningún enlace(s) debe aparecer en ningún sitio web que pueda interpretarse como difamatorio, obsceno o criminal, o que infrinja, de otra manera viole o defienda la infracción u otra violación de los derechos de terceros.

### Reserva de derechos:
Nos reservamos el derecho de solicitar que elimines todos los enlaces o cualquier enlace en particular a nuestro sitio web. Apruebas eliminar de inmediato todos los enlaces a nuestro sitio web cuando se solicite. También nos reservamos el derecho de modificar estos términos y condiciones y su política de enlaces en cualquier momento. Al vincular continuamente a nuestro sitio web, aceptas estar vinculado y seguir estos términos y condiciones de vinculación.

### Eliminación de enlaces de nuestro sitio web:
Si encuentras algún enlace en nuestro sitio que sea ofensivo por cualquier motivo, puedes contactarnos e informarnos en cualquier momento. Consideraremos las solicitudes para eliminar enlaces, pero no estamos obligados a hacerlo ni a responder directamente.

No nos aseguramos de que la información de este sitio web sea correcta. No garantizamos su integridad o precisión, ni prometemos asegurarnos de que el sitio web permanezca disponible o que el material en el sitio se mantenga actualizado.

### Exención de responsabilidad:
En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este. Nada en este descargo de responsabilidad:

limitará o excluirá nuestra responsabilidad o la tuya por muerte o lesiones personales; limitará o excluirá nuestra responsabilidad o la tuya por fraude o tergiversación fraudulenta; limitará cualquiera de nuestras responsabilidades o las tuyas de cualquier manera que no esté permitida por la ley aplicable; o excluirá cualquiera de nuestras responsabilidades o las tuyas que no puedan estar excluidas según la ley aplicable.

Las limitaciones y prohibiciones de responsabilidad establecidas en esta sección y en otras partes de este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) regirá todas las responsabilidades que surjan en virtud de la exención de responsabilidad, incluidas las responsabilidades que surjan en el contrato, en agravio y por incumplimiento de la obligación legal.

Siempre que el sitio web y la información y los servicios en el sitio se proporcionen de forma gratuita, no seremos responsables de ninguna pérdida o daño de cualquier naturaleza.
            
            `
        },
        'EN': {
            title: "Terms and Conditions",
            content: `
## Terms and Conditions
Welcome to anguitaosorio.cl!

These terms and conditions outline the rules and regulations for the use of Asesorías e Inversiones Celeste SpA's Website, located at https://www.anguitaosorio.cl.

By accessing this website, we assume you accept these terms and conditions. Do not continue to use anguitaosorio.cl if you do not agree to all of the terms and conditions stated on this page.

### Cookies:
The website uses cookies to enhance your online experience. By accessing anguitaosorio.cl, you agreed to use the necessary cookies.

A cookie is a text file that a web page server places on your hard drive. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie.

We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional cookies. There are some mandatory cookies that are necessary for the operation of our website. These cookies do not require your consent as they always operate. Please note that by accepting the required cookies, you also accept third-party cookies, which might be used through services provided by third parties if you use such services on our website, for example, a video viewing window provided by third parties and integrated into our website.

### License:
Unless otherwise stated, Asesorías e Inversiones Celeste SpA and/or its licensors own the intellectual property rights for all material on anguitaosorio.cl. All intellectual property rights are reserved. You may access this from anguitaosorio.cl for your own personal use subjected to restrictions set in these terms and conditions.

### You must not:
- Republish material from anguitaosorio.cl
- Sell, rent or sub-license material from anguitaosorio.cl
- Reproduce, duplicate or copy material from anguitaosorio.cl
- Redistribute content from anguitaosorio.cl

This Agreement shall begin on the date hereof.

Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas. Asesorías e Inversiones Celeste SpA does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Asesorías e Inversiones Celeste SpA, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Asesorías e Inversiones Celeste SpA shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.

Asesorías e Inversiones Celeste SpA reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.

### You warrant and represent that:
- You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
- The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;
- The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy.
- The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.

You hereby grant Asesorías e Inversiones Celeste SpA a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any of your Comments in any and all forms, formats, or media.

### Hyperlinking to our Content:
The following organizations may link to our Website without prior written approval:

- Government agencies;
- Search engines;
- News organizations;
- Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
- Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.

These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.

### We may consider and approve other link requests from the following types of organizations:
- Commonly-known consumer and/or business information sources;
- Dot.com community sites;
- Associations or other groups representing charities;
- Online directory distributors;
- Internet portals;
- Accounting, law, and consulting firms; and
- Educational institutions and trade associations.

We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Asesorías e Inversiones Celeste SpA; and (d) the link is in the context of general resource information.

These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.

If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Asesorías e Inversiones Celeste SpA. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.

### Approved organizations may hyperlink to our Website as follows:
- By use of our corporate name; or
- By use of the uniform resource locator being linked to; or
- By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.

No use of Asesorías e Inversiones Celeste SpA's logo or other artwork will be allowed for linking absent a trademark license agreement.

### Content Liability:
We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.

### Reservation of Rights:
We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.

### Removal of links from our website:
If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.

We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.

### Disclaimer:
To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:

- Limit or exclude our or your liability for death or personal injury;
- Limit or exclude our or your liability for fraud or fraudulent misrepresentation;
- Limit any of our or your liabilities in any way that is not permitted under applicable law; or
- Exclude any of our or your liabilities that may not be excluded under applicable law.

The limitations and prohibitions of liability set in this section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.

As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.            
`
        }
    },
}

            

export function getTermsOfUseData(id, language) {
    const default_language = "ES";
    if (termsOfUse_data[id] && termsOfUse_data[id][language]) {
      console.log("Data found for", id, "in", language); // Agrega esta línea para depurar
      return termsOfUse_data[id][language];
    }
    console.log("Using default language for", id); // Agrega esta línea para depurar
    return termsOfUse_data[id] ? termsOfUse_data[id][default_language] : null;
}
