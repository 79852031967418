import styled from "styled-components";

export const StyledSection = styled.div`
    z-index: 3;
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
    padding: 80px 50px 50px 50px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;

export const StyledTopSection = styled.div`
    z-index: 9;
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;


export const StyledSectionDarkSansSerif = styled(StyledSection)`
        background-color:black;
        h1,h2,h3,h4,h5,h6 {
            color:white;
        }
`;

export const StyledSectionDark = styled(StyledSectionDarkSansSerif)`
        font-family: 'MetaSerifPro-Medi';
        color:white;
        p {
         font-family: 'MetaSerifPro-Book';
        }
`;

export const StyledCenteredSection = styled(StyledSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align :center;
  `;