import React from 'react';
import { useTranslation } from 'react-i18next';
import AONav from '../components/Navbar';
import TwoColumnLeftImg from '../components/TwoColumnLeftImg';
import Footer from '../components/Footer';
import { getWebsiteData } from '../data/Website'; // Asegúrate de que la ruta sea correcta

const Nosotros = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // 'es' o 'en'
  const id = "nosotros"; // Identificador de los datos de 'Nosotros'

  const nosotrosContent = getWebsiteData(id, currentLanguage.toUpperCase());

  return (
    <>
      <AONav />
      <TwoColumnLeftImg 
        content={nosotrosContent.content}
        src={nosotrosContent.image}
        title={nosotrosContent.title}
        subtitle={nosotrosContent.subtitle}
      />
      <Footer />
    </>
  );
};

export default Nosotros;
